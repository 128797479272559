import * as React from 'react'
import ReactDOM from 'react-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import HCaptcha from '@hcaptcha/react-hcaptcha'

import { CaptchaBypassTypes, CaptchaInterface, CaptchaSize } from './types'

interface CaptchaProps {
  siteKey: string
  onVerify?: (response: string) => void
  type?: CaptchaBypassTypes
  size?: CaptchaSize
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}

const captchaOptions = (
  type,
  onVerify: (response: string) => void,
  ref,
  siteKey: string,
  size?: CaptchaSize,
) => {
  if (type === CaptchaBypassTypes.HCAPTCHA_BYPASS_TYPE) {
    return (
      <HCaptcha
        sitekey={siteKey}
        size={size}
        sentry={false}
        reCaptchaCompat={false}
        ref={ref}
        onVerify={onVerify}
      />
    )
  }

  return (
    <ReCAPTCHA ref={ref} size={size} onChange={onVerify} sitekey={siteKey} />
  )
}

export const Captcha = React.forwardRef<
  CaptchaInterface | undefined,
  CaptchaProps
>((props, ref) => {
  return typeof document !== 'undefined' && document.body
    ? ReactDOM.createPortal(
        captchaOptions(
          props.type,
          props.onVerify,
          ref,
          props.siteKey,
          props.size,
        ),
        props.containerRef?.current
          ? props.containerRef.current
          : document.body,
      )
    : null
})
