import * as React from 'react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'

import { useSiteConfig } from '../ConfigurationLoader'
import { useExtensions } from '../EnterpriseContext'

import { Captcha } from './Captcha'
import {
  CaptchaBypassTypes,
  CaptchaInterface,
  CaptchaSize,
  RateLimitBucket,
} from './types'

declare global {
  interface Window {
    recaptchaOptions?: { useRecaptchaNet?: boolean }
  }
}

enum ListAttackStrategies {
  LIST_ATTACK_STRATEGY_RECAPTCHA_VISIBLE = 'VISIBLE-RECAPTCHA',
  LIST_ATTACK_STRATEGY_RECAPTCHA_INVISIBLE = 'INVISIBLE-RECAPTCHA',
  LIST_ATTACK_STRATEGY_HCAPTCHA = 'HCAPTCHA',
  LIST_ATTACK_STRATEGY_TURNSTILE = 'TURNSTILE',
}

const BYPASS_MAP = {
  [ListAttackStrategies.LIST_ATTACK_STRATEGY_RECAPTCHA_VISIBLE]:
    CaptchaBypassTypes.RECAPTCHA_BYPASS_TYPE_VISIBLE,
  [ListAttackStrategies.LIST_ATTACK_STRATEGY_RECAPTCHA_INVISIBLE]:
    CaptchaBypassTypes.RECAPTCHA_BYPASS_TYPE_INVISIBLE,
  [ListAttackStrategies.LIST_ATTACK_STRATEGY_HCAPTCHA]:
    CaptchaBypassTypes.HCAPTCHA_BYPASS_TYPE,
  [ListAttackStrategies.LIST_ATTACK_STRATEGY_TURNSTILE]:
    CaptchaBypassTypes.TURNSTILE_BYPASS_TYPE,
}

type Callback = (response?: string) => void

export const useCaptcha = (config: {
  bucket: RateLimitBucket
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const extensions = useExtensions()
  const siteConfig = useSiteConfig()

  const captchaRef = React.useRef<CaptchaInterface>()
  const turnstileRef = React.useRef<TurnstileInstance | null>(null)
  const callbackRef = React.useRef<Callback>()

  const enableReCaptcha = siteConfig.enableRecaptchaNet

  const handleInteraction = React.useCallback((response) => {
    if (!response || !callbackRef.current) {
      return
    }

    callbackRef.current(response)
  }, [])

  React.useEffect(() => {
    typeof window !== 'undefined' &&
      enableReCaptcha &&
      (window.recaptchaOptions = {
        useRecaptchaNet: true,
      })
  }, [])

  const captchaObject = React.useMemo(() => {
    const firingBucket = extensions?.rateLimitersFiring?.find(
      (rateLimiter) =>
        rateLimiter.rateLimitingBucket === config.bucket.toString(),
    )

    const enabledRateLimitingBypass =
      firingBucket && siteConfig.listAttackStrategy
        ? firingBucket?.captchaBypassAvailable?.find(
            (bypass) =>
              bypass.type === BYPASS_MAP[siteConfig.listAttackStrategy!],
          )
        : undefined

    const captcha =
      enabledRateLimitingBypass?.type ===
      ListAttackStrategies.LIST_ATTACK_STRATEGY_TURNSTILE ? (
        <Turnstile
          siteKey={enabledRateLimitingBypass.siteKey}
          ref={turnstileRef}
          options={{
            refreshExpired: 'manual',
            retry: 'auto',
          }}
          tabIndex={0}
          onSuccess={handleInteraction}
          onError={() => turnstileRef?.current?.reset()}
          onExpire={() => turnstileRef?.current?.reset()}
        />
      ) : enabledRateLimitingBypass?.type ? (
        <Captcha
          type={enabledRateLimitingBypass.type}
          containerRef={config.containerRef}
          size={
            enabledRateLimitingBypass.type ===
            CaptchaBypassTypes.RECAPTCHA_BYPASS_TYPE_INVISIBLE
              ? CaptchaSize.INVISIBLE
              : undefined
          }
          siteKey={enabledRateLimitingBypass.siteKey}
          onVerify={handleInteraction}
          ref={captchaRef}
        />
      ) : null

    return {
      captcha,
      turnstileRef,
      type: enabledRateLimitingBypass?.type,
      submit: (callback: Callback) => {
        if (
          !enabledRateLimitingBypass?.type ||
          enabledRateLimitingBypass?.type ===
            CaptchaBypassTypes.RECAPTCHA_BYPASS_TYPE_VISIBLE
        ) {
          callback()
          return
        }

        if (
          enabledRateLimitingBypass?.type ===
          CaptchaBypassTypes.TURNSTILE_BYPASS_TYPE
        ) {
          const response = turnstileRef?.current?.getResponse()
          if (response) {
            callback(response)
            return
          }
        }

        // explicit logic which needs to be called for invisible reCaptcha
        callbackRef.current = callback

        captchaRef?.current?.reset && captchaRef.current.reset()
        captchaRef?.current?.execute && captchaRef.current.execute()
      },
    }
  }, [
    extensions?.rateLimitersFiring,
    config.bucket,
    siteConfig.listAttackStrategy,
    captchaRef,
    callbackRef,
  ])

  return captchaObject
}
