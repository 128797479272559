export enum RateLimitBucket {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTRATION',
  FORGOTTEN_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  MARKETING_MATERIAL_SIGN_UP = 'MARKETING_MATERIAL_SIGN_UP',
  ADD_REVIEW = 'ADD_REVIEW',
  WAITLIST = 'WAITLIST',
  REFERRAL_EMAIL = 'REFERRAL_EMAIL',
}

export enum CaptchaBypassTypes {
  RECAPTCHA_BYPASS_TYPE_VISIBLE = 'V2_VISIBLE',
  RECAPTCHA_BYPASS_TYPE_INVISIBLE = 'V2_INVISIBLE',
  HCAPTCHA_BYPASS_TYPE = 'HCAPTCHA',
  TURNSTILE_BYPASS_TYPE = 'TURNSTILE',
}

export enum CaptchaSize {
  COMPACT = 'compact',
  NORMAL = 'normal',
  INVISIBLE = 'invisible',
}

export type CaptchaInterface = {
  reset: () => void
  execute: () => void
}
